import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { NewModal } from '@components/NewModal/NewModal';
import { useTranslation } from 'react-i18next';
import { useFarmerDashboardCardStyles } from '@features/farmer/profile/components/FarmerDashboard/FarmerDashboardCard.style';
import { useGenerateResults } from '@features/farmer/profile/generateResults/useGenerateResults';
export const GenerateResultsModal = ({ open, onClose, farmSeason, farm, farmerName, carbonModel, }) => {
    var _a;
    const { t } = useTranslation();
    const { classes } = useFarmerDashboardCardStyles();
    const { generateResults, isLoading } = useGenerateResults({
        carbonResultModel: carbonModel,
        isBaseLine: farmSeason.baseline_farm_season_id === null,
        onSuccess: onClose,
    });
    return (_jsx(NewModal, { open: open, onClose: onClose, onConfirm: () => generateResults(farmSeason.id, farm.id), header: _jsx(NewModal.Header, { children: _jsx(NewModal.Title, { children: t('farmers.results.title') }) }), body: _jsx(NewModal.Body, { children: _jsxs(Typography, { children: [_jsx("span", { children: t('farmers.results.part1') }), _jsx("strong", Object.assign({ className: classes.bold }, { children: farm.name })), _jsx("span", { children: t('farmers.results.part2') }), _jsx("strong", Object.assign({ className: classes.bold }, { children: farmerName })), _jsx("span", { children: t('farmers.results.part3') }), _jsxs("strong", Object.assign({ className: classes.bold }, { children: [t('common.harvest'), " ", (_a = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season) === null || _a === void 0 ? void 0 : _a.harvest_year] })), "?"] }) }), footer: _jsxs(NewModal.Footer, { children: [_jsx(NewModal.Cancel, { children: t('constants.cancel') }), _jsx(NewModal.Confirm, Object.assign({ disabled: isLoading }, { children: t('farmers.results.confirm') }))] }) }));
};
