var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api } from '@services/api.service';
export const resultsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        changeResultPublication: builder.mutation({
            query: (body) => ({
                url: `/private/results/publication`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Farmer', 'Farmers'],
        }),
        generateResultsV2: builder.mutation({
            query: ({ farmSeasonId, farmId }) => ({
                url: `${process.env.FARMER_PORTAL_API}/v1/farm/${farmId}/season/${farmSeasonId}/results/generate`,
                method: 'POST',
            }),
            invalidatesTags: ['Farmer', 'Farmers'],
        }),
        generateResultsV3: builder.mutation({
            query: ({ farmSeasonId }) => ({
                url: `${process.env.FARMER_PORTAL_API}/v3/farm-seasons/${farmSeasonId}/results/generate`,
                method: 'POST',
            }),
            invalidatesTags: ['Farmer', 'Farmers'],
        }),
        getRawDndcResults: builder.query({
            query: ({ farmSeasonId }) => ({
                url: `/private/results/dndc-csv/${farmSeasonId}`,
                method: 'GET',
                responseHandler: (response) => __awaiter(void 0, void 0, void 0, function* () {
                    const blob = yield response.blob();
                    // Extract the filename from the Content-Disposition header
                    const contentDisposition = response.headers.get('Content-Disposition');
                    let filename = 'raw-results.csv'; // Default filename
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        if (match && match[1]) {
                            filename = match[1]; // Use filename from the header
                        }
                    }
                    // Create a download link and trigger the download
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename); // Set the extracted filename
                    document.body.appendChild(link);
                    link.click(); // Programmatically click the link to trigger the download
                    link.remove(); // Clean up the link element
                    // Clean up the object URL
                    window.URL.revokeObjectURL(url);
                }),
                cache: 'no-cache',
            }),
        }),
        getRawDndcLogs: builder.query({
            query: ({ farmSeasonId }) => ({
                url: `/private/results/dndc-logs/${farmSeasonId}`,
                method: 'GET',
                responseHandler: (response) => __awaiter(void 0, void 0, void 0, function* () {
                    const blob = yield response.blob();
                    // Extract the filename from the Content-Disposition header
                    const contentDisposition = response.headers.get('Content-Disposition');
                    let filename = 'raw-results.json'; // Default filename
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        if (match && match[1]) {
                            filename = match[1]; // Use filename from the header
                        }
                    }
                    // Create a download link and trigger the download
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename); // Set the extracted filename
                    document.body.appendChild(link);
                    link.click(); // Programmatically click the link to trigger the download
                    link.remove(); // Clean up the link element
                    // Clean up the object URL
                    window.URL.revokeObjectURL(url);
                }),
                cache: 'no-cache',
            }),
        }),
    }),
});
export const { useChangeResultPublicationMutation, useGenerateResultsV2Mutation, useGenerateResultsV3Mutation, useLazyGetRawDndcResultsQuery, useLazyGetRawDndcLogsQuery, } = resultsApi;
